/**
 * Валидирует все компоненты и вовзаращает все ли они корректны
 *
 * @param {import('vue').Component[]} refsList массив компонентов
 * @returns {boolean} все ли компоненты валидны
 */
const useValidateRefs = refsList => {
  if (!Array.isArray(refsList)) throw Error('Ожидался массив компонентов')

  let hasIncorrect = false

  refsList.forEach(refComp => {
    const validationField = refComp?.v$ ? 'v$' : '$v' // Старая версия $v, новая - v$

    if (!refComp?.[validationField]) return
    if (refComp[validationField]?.$touch) refComp[validationField].$touch()
    if (!hasIncorrect) hasIncorrect = refComp[validationField].$invalid
  })
  return !hasIncorrect
}

export default useValidateRefs
