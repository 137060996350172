<template>
  <div class="mini-form" v-cloak>
    <div class="white-block">
      <h2 class="title">Расчет стоимости</h2>
      <div v-for="(dot, index) in miniOrder.dots" :key="index">
        <dotComponent :dot="dot" :dotIndex="index" :ref="'dot' + index" />
      </div>
      <div class="order-types">
        <button
          v-for="type in orderTypes"
          class="order-type"
          :key="type.title"
          :class="type === miniOrder.chosenType ? 'chosen' : null"
          @click="updateType(type)"
          type="button"
        >
          <img :src="type.icon" alt="order-type" />
          <p>{{ type.title }}</p>
        </button>
      </div>
    </div>
    <button @click.prevent="setOrder" type="button" class="send">Рассчитать</button>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import grecaptcha from '@mixins/grecaptcha'
import dotComponent from './components/dotComponent.vue'

export default {
  name: 'MiniForm',
  components: { dotComponent },
  mixins: [grecaptcha],
  data() {
    return {
      orderTypes: [
        {
          id: 0,
          title: 'Пешком',
          icon: '/images/newMain/mini_form__courier.svg',
        },
        {
          id: 1,
          title: 'Автомобиль',
          icon: '/images/newMain/mini_form__b_class.svg',
        },
        {
          id: 2,
          title: 'Универсал',
          icon: '/images/newMain/mini_form__universal.svg',
        },
        {
          id: 3,
          title: 'Грузовой',
          icon: '/images/newMain/mini_form__cargo.svg',
        },
      ],
      initialDotsQuantity: 2,
    }
  },
  computed: {
    ...mapState(['miniOrder']),
  },
  methods: {
    ...mapActions(['passDataToOrderForm']),
    ...mapMutations(['createDot', 'updateToken', 'updateType']),
    setOrder() {
      const dots = Object.values(this.$refs).flat()

      dots.forEach(dot => {
        dot.touchDot()
      })

      // Находим заполенную точку
      const hasData = dots.find(dot => !dot.hasError)

      // Если такая есть - передаем данные для формы заказа
      if (hasData) {
        if (typeof ym !== 'undefined') ym(41864514, 'reachGoal', 'calculate_delivery')
        this.passDataToOrderForm()
      }
    },
  },
  created() {
    this.updateType(this.orderTypes[0])
    this.updateToken(this.tokenGenerator)

    for (let i = 0; i < this.initialDotsQuantity; i++) {
      if (i === 0)
        this.createDot({ items: [] }) // Если первая точка - устанавливаем, что товаров нет
      else this.createDot()
    }
  },
}
</script>
