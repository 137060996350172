function getClosestWorkDay() {
  const date = new Date()

  if (date.getHours() >= 21) {
    date.setDate(date.getDate() + 1)
  }

  return date.setHours(0, 0, 0, 0)
}

function timeToText(h, m) {
  const hours = h.toString().padStart(2, '0')
  const minutes = m.toString().padStart(2, '0')

  return `${hours}:${minutes}`
}

function getClosestTimeFrom(dotDate) {
  const date = new Date()

  if (dotDate < date) {
    const minutes = date.getMinutes() < 30 ? 30 : 0
    const hours = date.getHours() + +(+minutes === 0)

    return timeToText(hours, minutes)
  }

  return '00:00'
}

export default class Dot {
  constructor(props) {
    this.id = null

    this.suburb = null
    this.address = ''
    this.street = ''
    this.building = ''
    this.apartment = ''
    this.storey = ''
    this.entrance = ''
    this.lat = null
    this.lng = null
    this.metroId = props.metroId || ''
    this.isMetro = false
    this.isMetroNotFound = props.isMetroNotFound || false
    this.distanceFromMetro = props.distanceFromMetro || 0
    this.isSuggestionSet = null

    this.contact = ''
    this.phone = ''
    this.cash = 0
    this.comment = ''

    this.items = []

    this.isEnoughTime = true
    this.deliveryExtraPay = 0
    this.isSetAuto = false

    const dotDate = getClosestWorkDay()

    this.date = props.date || dotDate
    this.timeFrom = props.timeFrom || getClosestTimeFrom(dotDate)
    this.timeTo = props.timeTo || '23:59'

    this.isSuburb = props.isSuburb || false
    this.rightFormattedAddress = props.rightFormattedAddress || ''

    Object.keys(props).forEach(key => {
      this[key] = props?.[key]
    })
  }
}
