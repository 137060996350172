// Address book
export const NEW_ADDRESS_API = '/eApis/frontend/addressBook/add'
export const EDIT_ADDRESS_API = '/eApis/frontend/addressBook/edit'
export const ADDRESSES_LIST_API = '/eApis/frontend/addressBook/getAll'
export const SET_MAIN_ADDRESS_API = '/eApis/frontend/addressBook/setAddressMain'
export const DELETE_ADDRESS_API = '/eApis/frontend/addressBook/delete'
export const TRANSFER_TEMPLATES_TO_ADDRESS_BOOK_API = '/eApis/frontend/addressBook/transfer'
export const GET_ADDRESS_ITEM_API = '/eApis/frontend/addressBook/get'

// Verifiaction
export const COURER_DATA_API = '/ajax/user/findCourierForVerify'
export const VERIFICATION_FORM_API = '/ajax/user/VerifyCourier'
export const VERIFIED_COURERS_LIST_API = '/eApis/frontend/user/getVerifiedCouriers'

// User
export const CHECK_PHONE_AVAILABLE_API = '/ajax/user/checkIsPhoneAvailable'
export const SEND_REGISTRY_CODE_API = '/ajax/user/sendRegistryCode'
export const CHECK_REGISTRY_CODE_API = '/ajax/user/checkRegistryCode'
export const SAVE_ORDER_DRAFT_API = '/user/saveDraft'
export const GET_ORDER_DRAFT_API = '/ajax/orderForm/getDraft'
export const DELETE_ORDER_DRAFT_API = '/ajax/orderForm/deleteDraft'
export const REGISTER_BY_PHONE_API = '/ajax/user/registerByPhone'
export const GET_USER_WITH_TEMPLATES = '/ajax/user/getUserByPhoneWithTemplates'
export const UPDATE_CASHLESS_INFO_API = '/eApis/frontend/user/updateCashlessInfo'
export const UPDATE_CASHLESS_BILLING_API = '/eApis/frontend/user/updateCashlessBilling'
export const GET_EXTRA_USER_INFO_API = '/eApis/frontend/user/getExtraUserInfo'
export const DELETE_USER_API = '/eApis/frontend/user/deleteUser'
export const GET_USER_INFO_API = '/eApis/frontend/user/get'
export const EDIT_USER_API = '/eApis/frontend/user/editProfile'
export const CHANGE_PHONE_REQUEST_CONFIRM_API = '/eApis/frontend/user/confirmChangingPhone'
export const CANCEL_CHANGING_PHONE_API = '/eApis/frontend/user/cancelChangingPhone'
export const CHANGE_PHONE_API = '/eApis/frontend/user/changePhone'

// Address suggestions
export const GET_ADDRESSES_API = '/ajax/orderForm/getAddress'
export const GET_METRO_LIST_API = '/ajax/orderForm/getMetroList'
export const GET_CLOSESET_METRO_API = '/ajax/orderForm/GetClosestMetro'
export const GET_DISTANCE_FROM_METRO_API = '/ajax/orderForm/GetDistanceFromMetro'
export const GET_DISTANCE_ON_FOOT_API = '/ajax/orderForm/getCheckDistanceForOnFoot'

// Order
export const CHECK_PROMOCODE_API = '/ajax/orderForm/checkPromoCode'
export const GET_CHECK_PRINT_API = '/ajax/orderForm/getCheckPrint'
export const GET_SERVICES_API = '/ajax/orderForm/getServices'
export const BIND_CARD_API = '/ajax/user/PaymentCard'
export const CREATE_ORDER_API = '/ajax/orderForm/createOrder'
export const EDIT_ORDER_API = '/ajax/orderForm/editOrder'
export const GET_FORM_PROPS_API = '/eApis/frontend/order/GetFormProps'
export const GET_DOTS_LAST_ORDER_API = '/eApis/frontend/order/GetDotsFromLastOrders'
export const GET_ORDER_TEMPLATE_API = '/ajax/orderForm/getTemplateForOrderForm'
export const DELETE_ORDER_TEMPLATE_API = '/ajax/orderForm/deleteTemplate'

// Cabinet
export const GET_ORDERS_API = '/eApis/frontend/order/getMyOrders'
export const GET_DEBTORS_API = '/eApis/frontend/order/getDebtors'
export const GET_CSV_USER_ORDERS_API = '/eApis/frontend/order/GetCSVUserOrders'
export const GET_AVAIL_FILTERS_PARAMS_API = '/eApis/frontend/order/GetAvailFilterParams'
export const GET_COUNT_ORDERS_API = '/eApis/frontend/order/getCountOrders'
export const SAVE_TEMPLATE_API = '/eApis/frontend/order/saveTemplate'
export const ARCHIVE_ORDER_API = '/eApis/frontend/order/archiveOrder'
export const CANCEL_ORDER_CONTENT_API = '/eApis/frontend/order/getShopCancelMessage'
export const SEND_USER_FEEDBACK_API = '/eApis/frontend/user/SendFeedback'
export const GET_NEWS_API = '/eApis/frontend/user/getNews'
export const GET_INFO_STRING_API = '/eApis/frontend/user/getInfoString'

// Main
export const ASK_QUESTION_FROM_MAIN_API = '/eApis/frontend/auth/AskQuestionFromMain'
export const SEND_FEEDBACK_API = '/eApis/frontend/review/SendFeedback'
export const GET_REVIEWS_LIST_API = '/eApis/frontend/review/GetReviewsList'
export const GET_TOP_COURIERS_LIST = '/eApis/frontend/toolkit/getTopCouriers'
export const GET_TOPS_PERIODS = '/eApis/frontend/toolkit/getPeriodForTopCouriers'

// Pickup points
export const POINTS_LIST_API = '/eApis/frontend/user/PickupCenterList'
export const ADD_PICKUP_POINT_API = '/eApis/frontend/pickupCenter/add'
export const EDIT_PICKUP_POINT_API = '/eApis/frontend/pickupCenter/edit'
export const GET_PICKUP_POINT_API = '/eApis/frontend/pickupCenter/get'
export const DELETE_PICKUP_POINT_API = '/eApis/frontend/pickupCenter/delete'
export const SET_MAIN_PICKUP_POINT_API = '/eApis/frontend/pickupCenter/setPickupMain'
export const GET_PICKUP_FORM_PROPS_API = '/eApis/frontend/user/PickupCenterAddProp'
