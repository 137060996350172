export default class DotItem {
  constructor(props) {
    this.name = props?.name || ''
    this.count = props?.count || 1
    this.weight = props?.weight || 500
    this.price = props?.price || null
    this.index = props?.index || 1
    this.services = props?.services || []
  }
}
